
// Override default variables before the import
$primary:   #D61F84; /* #FF5EB5; */
$secondary: #6c757d;
$success:   #28a745;
$info:      #17a2b8;
$warning:   #ffc107;
$danger:    #dc3545;
$light:     #f8f9fa;
$dark:      #343a40;

$nav-link-color:                  $secondary;
$nav-underline-gap:               1rem;
$nav-underline-border-width:      2px;
$nav-underline-link-active-color: $primary;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "border": map-merge(
      map-get($utilities, "border"),
      ( responsive: true ),
    ),
  )
);

$custom-shadows: (
  null: $box-shadow,      // shadow
  "1": $box-shadow-sm,    // shadow-1
  "2": $box-shadow,       // shadow-2
  "3": $box-shadow-lg,    // shadow-3
  "none": none           // shadow-none
);

$utilities: map-merge(
  $utilities,
  (
    "shadow": (
      property: box-shadow,
      class: shadow,
      state: hover focus,  // Enables hover and focus variants
      values: $custom-shadows,
      responsive: true     // This enables all breakpoint variations
    )
  )
);

// $utilities: map-merge(
//   $utilities,
//   (
//     "display": map-merge(
//       map-get($utilities, "display"),
//       ( print: true ),
//     ),
//   )
// );

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';