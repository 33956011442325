
@property --color-bg {
    syntax: '<color>';
    inherits: false;
    initial-value: #2e2f42;
}

@property --color-inactive {
    syntax: '<color>';
    inherits: false;
    initial-value: #d6e2fb;
}

@property --color-active {
    syntax: '<color>';
    inherits: false;
    initial-value: white;
}

@property --color-shadow {
    syntax: '<color>';
    inherits: false;
    initial-value: #aec6f6;
}

@property --color-lighter {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #e4ffe6;
}

@property --color-light {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #FFC1E3;
}

@property --color-mid {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #FF5EB5;
}

@property --color-dark {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #D61F84;
}
@property --color-very-dark {
    syntax:        '<color>';
    inherits:      false;
    initial-value: #6B1042;
}

@font-face {
    font-family: 'silka';
    src: local('silka'), url(../fonts/silka-regular-webfont.woff2) format('woff2');
    font-weight: normal;
    text-rendering: optimizeLegibility;
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'silka';
    src: local('silka'), url(../fonts/silka-semibold-webfont.woff2) format('woff2');
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'silka';
    src: local('silka'), url(../fonts/silka-bold-webfont.woff2) format('woff2');
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

body {
    font-family: silka, Roboto, Helvetica, Arial, sans-serif;
}

/* some simple utilities */
.fullwidth       { width:          100%;  }
.fullheight      { height:         100%;  }
.scroll-x        { overflow-x:   scroll;  }
.scroll-x-auto   { overflow-x:     auto;  }
.scroll-y        { overflow-y:   scroll;  }
.scroll-y-auto   { overflow-y:     auto;  }
.w-10            { width:      10.0000%;  }
.w-20            { width:      20.0000%;  }
.w-30            { width:      30.0000%;  }
.w-40            { width:      40.0000%;  }
.w-60            { width:      60.0000%;  }
.w-70            { width:      70.0000%;  }
.w-80            { width:      80.0000%;  }
.w-33            { width:      33.3333%;  }
.w-66            { width:      66.6666%;  }
.mw-33           { min-width:  33.3333%;  }
.mw-66           { min-width:  66.6666%;  }

/** grid utilities **/
/* cols */
.grid-col-1      { grid-column-start: 1; }
.grid-col-2      { grid-column-start: 2; }
.grid-col-3      { grid-column-start: 3; }
.grid-col-4      { grid-column-start: 4; }
.grid-col-5      { grid-column-start: 5; }

/* col span */
.grid-col-span-1 { grid-column: span 1 }
.grid-col-span-2 { grid-column: span 2 }
.grid-col-span-3 { grid-column: span 3 }
.grid-col-span-4 { grid-column: span 4 }
.grid-col-span-5 { grid-column: span 5 }

/* rows */
.grid-row-1      { grid-row-start: 1; }
.grid-row-2      { grid-row-start: 2; }
.grid-row-3      { grid-row-start: 3; }
.grid-row-4      { grid-row-start: 4; }
.grid-row-5      { grid-row-start: 5; }

/* row span */
.grid-row-span-1 { grid-row: span 1; }
.grid-row-span-2 { grid-row: span 2; }
.grid-row-span-3 { grid-row: span 3; }
.grid-row-span-4 { grid-row: span 4; }
.grid-row-span-5 { grid-row: span 5; }

.grid-area-a { grid-area: a; }
.grid-area-b { grid-area: b; }
.grid-area-c { grid-area: c; }
.grid-area-d { grid-area: d; }
.grid-area-e { grid-area: e; }
.grid-area-f { grid-area: f; }

.d-flex-align-center {
    display:     flex;
    align-items: center;
    gap:         0.25rem;
}

.ws-pre { white-space: pre; font-variant-numeric: tabular-nums lining-nums; }

.status-btn:hover, .status-btn:focus, .status-btn:not(:disabled):not(.disabled):active, .status-btn:not(:disabled):not(.disabled).active, .show > .status-btn.dropdown-toggle {
    color: var(--bs-body-color);
    background-color: var(--bs-body);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(255,94,181,.25);
    overflow: visible;
}

#main {
    height:             100vh;
    display:            grid;
    grid-template-rows: auto 1fr;
}

#menu {
    background-color: var(--color-bg);
}

#content {
    overflow-y: auto;
    height:     100%;
}

#facility-main {
    display:               grid;
    grid-template-columns: 10fr 90fr;
}

#facility-menu {
    display:               grid;
    grid-template-columns: 1fr max-content 1em max-content 1fr;
    grid-auto-rows:        3.5em;
    background-color:      white;
}

.facility-menu-item {
    width:                 100%;
    grid-column:           span 5;
    display:               grid;
    grid-template-columns: subgrid;
    grid-template-rows:    subgrid;
    justify-content:       center;
    align-content:         baseline;
    align-items:           center;
    padding:               4px;
    border-radius:         1em;
    /* border-bottom:      1px darkgray solid; */
    background-color:      white;
    text-decoration:       none;
}

.facility-menu-header {
    width:                 100%;
    grid-column:           span 5;
    display:               grid;
    grid-template-columns: subgrid;
    grid-template-rows:    subgrid;
    justify-content:       center;
    align-content:         baseline;
    align-items:           center;
    padding:               4px;
    border-radius:         1em;
    /* border-bottom:      1px darkgray solid; */
    background-color:      white;
    text-decoration:       none;
}

.facility-menu-item.active {
    background-color: var(--bs-primary);
    color:            white;
}

.facility-menu-item:hover {
    background-color: rgb(240, 240, 240);
}

.facility-menu-item.active:hover {
    background-color: var(--bs-primary);
    color:            white;
}

.facility-menu-item.my-active:hover {
    background-color: var(--bs-primary);
    color:            white;
}

@media (min-width: 768px) {
    .my-active {
        background-color: var(--bs-primary);
        color:            white;
    }
}

.facility-result {
    display:               grid;
    grid-template-columns: max-content 1fr;
    cursor:                pointer;
}

.facility-result-pic {
    max-width:     100px;
    border-radius: 1em;
    margin-right:  1em;
}

.facility-result-info {
    display:               grid;
    grid-template-columns: 1fr auto;
    grid-template-rows:    1fr auto;
}

.facility-result-bottom {
    grid-row:              2;
    grid-column:           span 2;
    display:               grid;
    grid-template-columns: 1fr 1fr;
}

.facility-parker-application {
    display:               grid;
    grid-template-columns: auto auto;
}

.two-col-align-first {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.5em;
}

.two-col-compact {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 0.5em;
}
.three-col-compact {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    gap: 0.5em;
}

.three-col-subgrid {
    display:               grid;
    grid-template-columns: subgrid;
    gap:                   0.5em;
}

.four-col-compact {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    gap: 0.5em;
}


.two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
}

#about {
    height:          100%;
    display:         flex;
    align-items:     center;
    justify-content: center;
}

.rate-builder-type-button {
    height: 8em;
}

.credit-card {
    width:               15em;
    display:             inline-grid;
    aspect-ratio:        85 / 53;
    grid-template-areas: "a a a"
                         ". . ."
                         "b b b"
                         "c c c";
    gap:               0.25em;
    grid-auto-columns: 1fr;
    grid-auto-rows:    1fr;
    background: rgb(203,203,203);
    background: linear-gradient(325deg, rgba(203,203,203,1) 0%, rgba(255,255,255,1) 100%);
}

/* google maps stuff */
.gm-style iframe + div { border: none !important; }

/* .pac-container { }

.pac-icon { } */

.pac-item {
    padding:    0.5em;
    border-top: 1px solid #e6e6e6;
    font-size:  11px;
}

/*

.pac-item:hover { }

.pac-item-selected { }

.pac-item-query { }

.pac-matched { }

*/

.pac-container:after {
    content:none !important;
}

/* table stuff */
.tab-content {
    height: 100%;
}

.zoom-small:hover {
    transition: transform .33s;
    transform: scale(1.035); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    /* border: 2px solid var(--bs-primary); */
}

.zoom-small-link:hover {
    transition: transform .33s;
    transform: scale(1.035); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    border: 2px solid var(--bs-primary);
    cursor: pointer;
}
.zoom-small-link:not(:hover) {
    transition: transform .33s;
    transform: scale(1.00); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    border: 2px solid var(--color-light);
}

.zoom-small:not(:hover) {
    transition: transform .33s;
    transform: scale(1.00); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}